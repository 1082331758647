<template>
  <div class="row">
    <PageTitle :title="'Pesanan Berjalan'" />
  </div>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body p-9">
      <!-- <div class="row mb-3">
        <div style="flex: 0 0 auto; width: 88%">
          <h3 class="fw-bolder">Pesanan Berjalan</h3>
        </div>
      </div>
      <hr /> -->
      <Datatable v-if="refreshTable" ref="Datatable" :table-header="tabelHeader" :dataurl="dataurl" :filter="listFilter"
        :search="search" :routeurl="routeurl" :deleteurl="deleteurl" :methodeurl="methodeurl">
        <template v-slot:cell-pr_code="{ row: data }">
          {{ data.pr_code }}
        </template>
        <template v-slot:cell-po_code="{ row: data }">
          {{ data.po_code }}
        </template>
        <template v-slot:cell-status="{ row: data }">
          {{ data.status }}
        </template>
        <template v-slot:cell-request_date="{ row: data }">
          {{ data.request_date.replace("T", " ") }}
        </template>
        <template v-slot:cell-updated_at="{ row: data }">
          {{ data.updated_at }}
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import PageTitle from '@/components/PageTitle.vue'
export default {
  components: {
    Datatable,
    PageTitle
  },
  data() {
    const fields = [
      { key: "counter", name: "no", sortable: true },
      { key: "pr_code", name: "No PR", sortable: true },
      { key: "po_code", name: "No PO", sortable: true },
      { key: "status", name: "Status Approval", sortable: true },
      { key: "request_date", name: "Tanggal Pengajuan", sortable: true },
      {
        key: "updated_at",
        name: "Tanggal Terakhir Perubahan",
        sortable: true,
      },
    ];
    return {
      dataurl: "/dashboard/v1/query-ongoing-order",
      methodeurl: "post",
      routeurl: "dashboard",
      deleteurl: "",
      deleteurl2: "",
      tabelHeader: fields,
      search: null,
      loading: false,
      dialogVisibleDelete: false,
      valDelete: null,
      listFilter: [{}],
      refreshTable: true,
      branch_id: "",
      profile: JSON.parse(this.$store.getters.currentUser),
    };
  },
  mounted() {
    this.getUserDetail();
    setCurrentPageBreadcrumbs("Pesanan Berjalan", [
      {
        title: "Dashboard",
        link: "/dashboard",
      },
      {
        title: "Pesanan Berjalan",
        link: "#",
      },
    ]);
  },
  watch: {
    search(val) {
      if (val.length < 1) {
        this.listFilter[0].role_name = null;
        this.$refs.Datatable.fetchData;
      }
    },
  },
  methods: {
    getUserDetail() {
      this.$apiservice.query('/user/v1/get-user-detail', { params: { nip: this.profile.data.nip } }).then(res => {
        this.branch_id = res.data.branch_id;

        this.listFilter[0].branch_id = res.data.branch_id;
        this.forceUpdate();
      })
    },

    cariData() {
      let val = this.search;
      if (val.length > 3) {
        this.listFilter[0].role_name = this.search;
        this.forceUpdate();
      }
    },
    add() {
      this.$router.push({ name: "master-akses-user-create" });
    },
    remoteMethod(val) {
      if (val) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.options.filter((item) => {
            return item.label.toLowerCase().includes(val.toLowerCase());
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    btnDetail(val) {
      this.$router.push({
        name: this.routeurl + "-detail",
        params: { id: val },
      });
    },
    btnConfirmHapus(val) {
      this.dialogVisibleDelete = true;
      this.valDelete = val;

    },
    btnHapus(val) {
      this.dialogVisibleDelete = false;
      this.$apiservice.setHeader();
      this.$apiservice.delete(this.deleteurl + "?roleId=" + val).then((res) => {
        Swal.fire({
          title: "Data Terhapus",
          html: "Data berhasil dihapus",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Oke",
          customClass: {
            confirmButton: "btn fw-bold btn-success",
          },
        }).then(() => {
          this.$refs.Datatable.fetchData();
        });
      });
    },
    goBack() {
      return this.$router.go(-1);
    },
    forceUpdate() {
      this.refreshTable = false;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
  },
};
</script>
